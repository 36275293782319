import config from 'temp/config';
import { ComponentPropsContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/page-props';
import NotFound from 'src/NotFound';
import { componentBuilder } from 'temp/componentBuilder';
import Layout from 'src/Layout';
import { GetStaticProps, NextPage } from 'next';
import { siteResolver } from 'lib/site-resolver';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { FastSitecoreContext } from 'helpers/FastSitecoreContext/FastSitecoreContext';
import { useState, useEffect } from 'react';

const Custom404: NextPage<SitecorePageProps> = (props) => {
  const [notFoundPageProps, setNotFoundPageProps] = useState(props);

  // If 404 layout data is not found but we have 404 layout data available in session storage
  // then utilize stored props data as backup
  useEffect(() => {
    if (notFoundPageProps && Object.keys(notFoundPageProps)?.length > 0) {
      sessionStorage.setItem('NotFoundPageProps', JSON.stringify(props));
    }

    if (!notFoundPageProps?.layoutData?.sitecore?.route) {
      const storedLayoutData = JSON.parse(sessionStorage.getItem('NotFoundPageProps') || '{}');
      setNotFoundPageProps(storedLayoutData);
    }
    // props comes from layout service which won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  try {
    const { componentProps, layoutData, headLinks } = notFoundPageProps;
    const isEditing = layoutData?.sitecore?.context?.pageEditing;
    if (!layoutData?.sitecore?.route) {
      return <NotFound />;
    }
    return (
      <ComponentPropsContext value={componentProps}>
        <FastSitecoreContext
          componentFactory={componentBuilder.getComponentFactory({ isEditing })}
          layoutData={layoutData}
        >
          <Layout layoutData={layoutData} headLinks={headLinks} />
        </FastSitecoreContext>
      </ComponentPropsContext>
    );
  } catch (e) {
    console.log(e);
    return <NotFound />;
  }
};

export const getStaticProps: GetStaticProps = async () => {
  const site = siteResolver.getByName(config.sitecoreSiteName);

  let props: SitecorePageProps | null = null;

  try {
    props = await sitecorePagePropsFactory.create({
      params: {
        path: [`_site_${site.name}/_404`],
      },
    });
  } catch (error) {
    console.log('Error occurred while fetching error pages');
    console.log(error);
  }

  return {
    props: {
      ...props,
    },
  };
};

export default Custom404;
